














import { Component, Ref } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import SettingsCoursesDashboardModal, { VisibleMasterGroupForm } from '@/components/modals/SettingsCoursesDashboardModal.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterEducationModule from '@/store/modules/master/education'

@Component({
  components: {
    Confirmation,
    SettingsCoursesDashboardModal,
  },
})
export default class CoursesSettings extends NotifyMixin {
  @Ref() confirm!: Confirmation

  private formCourses: VisibleMasterGroupForm[] = []
  private showSettingsCourses = false

  private fetchMasterGroupsAll() {
    this.formCourses = []
    MasterEducationModule.fetchMasterGroupsAll({ all: true })
      .then(response => {
        if (response.length) {
          response.filter(group => group.dashboardShow).map((group, index) => {
            this.formCourses.push({
              courseId: group.course.id,
              isVisible: group.isVisible,
              position: group.position || index + 1,
              title: group.course.title,
            })
          })
          this.showSettingsCourses = true
        } else {
          this.confirm.open(
            'Показ курсов на платформе',
            'Привет! У тебя пока только один курс, но когда их станет больше можно будет менять их расположение на домашнем экране, а также скрывать и открывать для просмотра неактуальные для тебя курсы.',
            {
              buttonCancelVisible: false,
              buttonConfirmText: 'Понятно',
              skin: 'secondary',
            })
            .catch(() => {return})
        }
      })
      .catch(this.notifyError)
  }
}
